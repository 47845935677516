export const RECIPE_CARD = 'recipe-card';
export const RECIPE_CARD_PROPERTIES = 'recipe-card-properties';
export const RECIPE_SERVINGS = 'recipe-servings';
export const RECIPE_SERVINGS_AMOUNT = 'recipe-servings-amount';
export const RECIPE_SERVINGS_PLUS = 'recipe-servings-plus';
export const RECIPE_SERVINGS_MINUS = 'recipe-servings-minus';
export const LOGIN_MODAL = 'shared-login-modal';
export const LOGIN_MODAL_LOGIN_BUTTON = 'shared-login-modal-login';
export const LOGIN_MODAL_REGISTER_BUTTON = 'shared-login-modal-register';
export const LOGIN_MODAL_CLOSE_BUTTON = 'shared-login-modal-close';
export const FAVORITE_BUTTON = 'recipe-card-favorite';
export const FAVORITE_CATEGORY_CHECKBOX = 'favorites-list-button';
export const FAVORITE_ADD_CATEGORY_BUTTON = 'favorites-add-list-button';
export const FAVORITE_ADD_CATEGORY_NAME_FIELD = 'favorites-recipe-list-input';
export const FAVORITE_ADD_CATEGORY_SUBMIT_BUTTON = 'favorites-add-list-submit-button';
export const FAVORITE_SAVE_CATEGORIES_BUTTON = 'favorites-list-add-button';
export const FAVORITE_CLOSE_BUTTON = 'favorites-modal-close';
export const VIDEO_CARD = 'video-card';
export const VIDEO_CARD_PROPERTIES = 'video-card-properties';
export const SUGGESTIONS_MODAL = 'product-suggestions-modal';
export const SUGGESTIONS_ADD = 'product-suggestions-add-to-cart-button';
export const SUGGESTIONS_CLOSE = 'panel-header-close-button';
export const SUGGESTIONS_INGREDIENT = 'suggestion_ingredient';
export const SUGGESTIONS_REUSABLE_INGREDIENT = 'suggestion_reusable_ingredient';
export const SUGGESTIONS_PLACEHOLDER = 'product_textPlaceholder';
export const SUGGESTIONS_PRODUCT_PANE = 'product-pane';
export const SUGGESTIONS_PRODUCT_LIST = 'product-list';
export const SUGGESTIONS_PRODUCT_CARD = 'product-card';
export const SUGGESTIONS_ADDED_TO_BASKET_MODAL = 'product-suggestions-added-to-basket-modal';
export const SUGGESTIONS_ALTERNATIVE_PRODUCTS_PANE = 'suggestion-alternatives-result';
export const SUGGESTIONS_VAGUE_TERMS_PRODUCTS_PANE = 'suggestion-vague-terms-result';
