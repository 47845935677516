'use client';

import { createContext } from 'react';
import type { RecipeImage } from '../graphql/_generated-graphql-types';
import type {
    RecipeCollectionCategoryFragment,
    RecipeOriginType,
} from '../graphql/_generated-operation-types';

export type RecipeCollectionData = {
    isModalOpen: boolean;
    handleOnClose: () => void;
    handleOnOpen: (
        recipe: {
            id: number;
            type?: RecipeOriginType;
            images?: RecipeImage[];
        },
        options?: { recipeCreatedMessage?: string },
    ) => void;

    categories: RecipeCollectionCategoryFragment[];
    isLoading: boolean;
    isChangeLoading: boolean;
    isChangeError: boolean;
    canCreateCategories: boolean;
    canFavoriteRecipes: boolean;
};

export const RecipeCollectionContext = createContext<RecipeCollectionData>({
    isModalOpen: false,
    handleOnClose: () => null,
    handleOnOpen: () => null,
    categories: [],
    canCreateCategories: false,
    canFavoriteRecipes: false,
    isLoading: false,
    isChangeLoading: false,
    isChangeError: false,
});
